@import "base/reset";
@import "components/places";
@import "components/input";
@import "components/containers";
@import "components/progress-bar";
@import "components/buttons";

.fill-primary-contrast {
  fill: var(--primary-palette-contrast-500);
}

.fill-primary {
  fill: var(--primary-palette-500);
}

.fill-accent-contrast {
  fill: var(--accent-palette-contrast-500);
}
