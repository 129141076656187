// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {

    .colored-theme {
      .maker-container {
        background: white;
        border-bottom-right-radius: 24px;
        border-top-right-radius: 24px;
        color: var(--accent-palette-500);
      }
    }

  }
}
